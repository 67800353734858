<template>
  <div
    class="HomePagePartnershipContent"
    data-aos="fade-up"
    data-aos-duration="1000"
    data-aos-delay="300"
    data-aos-offset="0"
    data-aos-easing="ease-out"
  >
    <div class="HomePagePartnershipContent-Title">{{ title }}</div>
    <p class="HomePagePartnershipContent-Text">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: "HomePagePartnershipContent",
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.HomePagePartnershipContent {
  position: relative;
  z-index: 1;
  margin-top: 18px;

  @media (min-width: 768px) {
    margin-top: 28px;
  }

  &-Title {
    margin-bottom: 16px;
    font-size: 20px;
    letter-spacing: 0.03em;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: 22px;
    }
  }

  &-Text {
    margin: 0 0;
    font-family: "Exo";
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: #98a6b9;

    @media (min-width: 768px) {
      font-size: 16px;
    }
    @media (min-width: 1024px) {
      font-size: 18px;
    }
  }
}
</style>
