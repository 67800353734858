<template>
  <div class="affiliates-item">
    <h3 class="affiliates-item-title">
      <img src="@/assets/images/home/affiliates/circle.svg" alt="">
      <span>
        {{ title }}
      </span>
    </h3>
    <div class="affiliates-item-content">
      <p>
        {{ text }}
      </p>
      <a
          v-if="link"
          :href="link"
          target="_blank"
      >
        <span>
            visit the website
        </span>
        <BaseIcon name="arrow-right-small" />
      </a>
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/components/Base/BaseIcon";

export default {
  name: "HomePageAffiliatesItem",
  components: { BaseIcon },
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
.affiliates-item {
  display: flex;
  flex-direction: column;
  padding: 10px 0 0;
  border-top: 1px solid #828FA1;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  &:not(:last-child) {
    margin-bottom: 80px;
  }

  p {
    margin: 0;
  }

  &-title {
    display: flex;
    align-items: center;
    margin: 0 0 19px;
    font-size: 20px;
    line-height: 1.25;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    font-weight: 400;

    @media (min-width: 768px) {
      margin: 0;
      font-size: 22px;
    }

    @media (min-width: 1024px) {
      font-size: 28px;
    }

    > svg {
      width: 10px;
      margin-right: 8px;

      @media (min-width: 768px) {
        margin-right: 26px;
      }

      @media (min-width: 1024px) {
        width: 14px;
        margin-right: 44px;
      }

      @media (min-width: 1440px) {
        width: 16px;
        margin-right: 102px;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    // идеально вписаловь в резиновость
    flex: 0 0 40%;

    > p {
      font-family: 'Exo', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;
      color: #98A6B9;

      @media (min-width: 768px) {
        font-size: 16px;
      }

      @media (min-width: 1024px) {
        font-size: 20px;
      }
    }

    > a {
      display: flex;
      align-items: center;
      margin-top: 16px;
      font-weight: 700;
      font-size: 12px;
      line-height: 1.05;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      text-decoration: none;
      color: inherit;
      cursor: pointer;

      @media (min-width: 768px) {
        font-size: 14px;
      }

      @media (min-width: 1024px) {
        margin-top: 24px;
      }

      @media (min-width: 1440px) {
        margin-top: 32px;
      }

      > span {
        margin-right: 12px;

        @media (min-width: 768px) {
          margin-right: 16px;
        }
      }
    }
  }
}
</style>
