<template>
  <div class="HomePageNewsCard" @click="handleClick(item.url)">
    <span class="HomePageNewsCard-Date">{{ item.date }}</span>
    <div class="HomePageNewsCard-Title">{{ item.title }}</div>
    <p class="HomePageNewsCard-Text">{{ item.text }}</p>
    <img
      class="HomePageNewsCard-Background"
      :src="require(`@/assets/images/holding/${item.bg}.png`)"
      :alt="item.bg"
    />
    <img
      v-if="item.logo"
      class="HomePageNewsCard-Logo"
      :src="require(`@/assets/images/holding/${item.logo}.svg`)"
      :alt="item.logo"
    />
    <div class="HomePageNewsCard-Button">
      <BaseIcon name="chevron-right" :width="50" :height="70" />
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/components/Base/BaseIcon.vue";

export default {
  name: "HomePageNewsCard",
  components: { BaseIcon },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleClick(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss">
.HomePageNewsCard {
  font-family: "Oxygen", sans-serif;
  font-style: normal;
  font-weight: 400;
  padding: 48px 30px;
  width: 444px !important;
  height: 520px;
  border-radius: 9px;
  border-bottom: 1px solid #828fa1;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background: url(~@/assets/images/holding/vector.png);

  @media (max-width: 768px) {
    width: 288px !important;
    height: 338px;
    background-size: contain;
    padding: 40px 14px;
  }

  &::after {
    content: "";
    width: 80%;
    height: 20px;
    position: absolute;
    bottom: 0;
    left: 10px;
    right: 0;
    background: rgba(1, 12, 40, 1);
    box-shadow: 44px -24px 84px 107px rgba(1, 12, 40, 1);
  }

  &:hover {
    background: url(~@/assets/images/holding/vector-hover.png);

    @media (max-width: 768px) {
      background-size: contain;
    }

    .HomePageNewsCard-Background {
      transform: translateY(-10px);
    }

    .HomePageNewsCard-Button {
      background: #1f2941;

      > svg {
        color: #ffffff;
      }
    }
  }

  &-Date {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.05;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #828fa1;
    position: absolute;
    top: 10px;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  &-Title {
    font-size: 28px;
    line-height: 48px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #f0f0f0;
    margin-bottom: 16px;

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 25px;
    }
  }

  &-Text {
    margin: 0;
    font-family: "Exo", sans-serif;
    font-size: 20px;
    line-height: 27px;
    color: rgba(152, 166, 185, 1);

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &-Background {
    position: absolute;
    bottom: 0;
    left: 20px;
    width: 70%;
    transition: all 0.3s ease-in-out;
    z-index: 0;
    mix-blend-mode: exclusion;

    @media (max-width: 768px) {
      width: 220px;
    }
  }

  &-Logo {
    position: absolute;
    bottom: 48px;
    left: 30px;
    z-index: 1;

    @media (max-width: 768px) {
      height: 50px;
      max-width: 180px;
      bottom: 13px;
      left: 14px;
    }
  }

  &-Button {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #00ffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 48px;
    right: 30px;
    z-index: 1;
    transition: all 0.3s ease-in-out;

    > svg {
      transition: all 0.3s ease-in-out;
    }

    @media (max-width: 768px) {
      width: 65px;
      height: 65px;
      bottom: 13px;
      right: 14px;

      svg {
        width: 35px;
        height: 50px;
      }
    }
  }
}
</style>
