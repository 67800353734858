<template>
  <form class="form" @submit.prevent="onSubmit">
    <input
        v-model="form.email"
        type="text"
        placeholder="Your contact e-mail"
        :class="{'form-error__border': isError}"
    >
    <p v-if="isError" class="form-error">Invalid email</p>
    <textarea
        v-model="form.comment"
        placeholder="Comment"
    />
    <button type="submit" class="form-btn">
      Apply now
      <img src="../assets/images/btn_arrow.svg" alt="">
    </button>
  </form>
</template>

<script>
import {email, required} from "vuelidate/lib/validators";
import axios from "axios";
import {validationMixin} from "vuelidate";

export default {
  name: "Form",
  mixins: [validationMixin],
  data: () => ({
    form: {
      email: '',
      comment: '',
    }
  }),
  validations: {
    form: {
      email: {
        required,
        email,
      }
    }
  },
  computed: {
    isError() {
      return this.$v.form.email.$error
    }
  },
  methods: {
    async onSubmit() {
      this.$v.form.$touch();
      if (this.isError) return;

      await axios.post(
          '/mail.php',
          this.form,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          }
      )
          .then(() => {
            Object.keys(this.form).forEach(key => {
              this.form[key] = ''
            })
            this.$nextTick(() => {
              this.$v.$reset()
            })
          })
          .catch(() => {
          })
    },
  }
}
</script>

<style lang="scss">
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 90px auto 0;

  input,
  textarea {
    width: 100%;
    padding: 15px 15px;
    font-size: 16px;
    font-family: 'Oxygen';
    color: #828FA1;
    border-radius: 8px;
    border: 1px solid #828FA1;
    background: transparent;
  }

  input {
    transition: all .3s;
  }

  textarea {
    margin-top: 10px;
    resize: none;
    min-height: 108px;
    margin-bottom: 14px;
  }

  &-error {
    align-self: flex-start;
    margin: 10px 0 0;
    padding-left: 10px;
    font-family: "Oxygen";
    font-size: 14px;
    color: #C22B2B;
    transition: display .3s;

    &__border {
      border: 1px solid #C22B2B !important;
    }
  }

  &-btn {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 17px 15px;
    font-family: 'Oxygen', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.05;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #F0F0F0;
    cursor: pointer;
    background: #2F5BD8;
    border-radius: 8px;
    transition: all .3s;

    img {
      margin-left: auto;
      opacity: 0;
      transition: all .3s;
    }

    &:hover {
      background: #0E339C;

      img {
        opacity: 1;
      }
    }
  }
}
</style>
