<template>
  <div class="HomePageNews">
    <div class="HomePageNews-Items" data-aos="fade-up" data-aos-duration="1500">
      <VueSlickCarousel ref="holding" v-bind="settings">
        <HomePageNewsCard v-for="(item, i) in items" :key="i" :item="item" />
      </VueSlickCarousel>
      <div class="HomePageNews-Arrows">
        <BaseIcon
          name="arrow-left"
          :width="40"
          :height="30"
          class="prev"
          @click="$refs.holding.prev()"
        />
        <BaseIcon
          name="arrow-right"
          :width="40"
          :height="30"
          class="next"
          @click="$refs.holding.next()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import HomePageNewsCard from "@/views/Home/_components/HomePageNews/HomePageNewsCard";
import BaseIcon from "@/components/Base/BaseIcon.vue";

export default {
  name: "HomePageNews",
  components: { BaseIcon, HomePageNewsCard, VueSlickCarousel },
  data: () => ({
    items: [
      {
        date: "01.03.2023",
        title: "European Gaming",
        text: "Why is iGaming one of the most promising niches for a job seeker today?",
        url:
          "https://europeangaming.eu/portal/latest-news/2023/03/01/131152/why-is-igaming-one-of-the-most-promising-niches-for-a-job-seeker-today",
        logo: "european-gaming",
        bg: "card5",
      },
    //   {
    //     date: "18.08.2022",
    //     title: "IGBAFFILIATE",
    //     text: "Advantages of working in the iGaming sector",
    //     url:
    //       "https://europeangaming.eu/portal/latest-news/2022/08/18/119873/advantages-of-working-in-the-igaming-sector",
    //     logo: "igbaffiliate",
    //     bg: "card4",
    //   },
    //   {
    //     date: "31.08.2021",
    //     title: "IGBAFFILIATE",
    //     text: "DUXGroup: People are the core asset in our business",
    //     url:
    //       "https://www.igbaffiliate.com/partner-insights/duxgroup-people-are-the-core-asset-in-our-business",
    //     logo: "igbaffiliate",
    //     bg: "card2",
    //   },
    //   {
    //     date: "11.07.2021",
    //     title: "European Gaming",
    //     text: "Hurricane Global became DUXGroup and acquired PinoCasino",
    //     url:
    //       "https://europeangaming.eu/portal/latest-news/2021/07/14/96210/hurricane-global-became-duxgroup-and-acquired-pinocasino",
    //     logo: "european-gaming",
    //     bg: "card1",
    //   },
    ],
    settings: {
      arrows: false,
      dots: false,
      slidesToShow: 2.5,
      infinite: false,
      touchMove: true,
      variableWidth: true,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
  }),
};
</script>

<style lang="scss">
.HomePageNews {
  margin-top: 90px;

  &-Items {
    padding-left: 25px;

    .slick-slide {
      margin-right: 20px !important;
    }
  }

  &-Arrows {
    display: flex;
    margin-top: 50px;

    .prev,
    .next {
      cursor: pointer;
    }
    .prev {
      margin-right: 9px;
    }
  }
}
</style>
