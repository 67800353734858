<template>
  <section class="affiliates">
    <HomePageAffiliatesItem
      v-for="(item, i) in items"
      :key="i"
      :title="item.title"
      :text="item.text"
      :link="item.link"
      data-aos="fade-up"
      data-aos-duration="1500"
    />
  </section>
</template>

<script>
import HomePageAffiliatesItem from "@/views/Home/_components/HomePageAffiliates/HomePageAffiliatesItem";

export default {
  name: "HomePageAffiliates",
  components: { HomePageAffiliatesItem },
  data: () => ({
    items: [
      {
        title: "DuxOperation",
        text:
          "A team of professionals in the field of casino and sports operations. We have experience working with the largest brands in the world. We have the following departments: CRM, VIP, support, antifraud, payments, BI, and more.",
      },
      {
        title: "DUXVentures",
        text:
          "We invest money in what we understand: iGaming, mobile marketing, digital, and crypto. The main principle is 1+1=3. We don’t just offer money. We give the project our expertise, experience, skills, and abilities.",
      },
    ],
  }),
};
</script>

<style lang="scss">
.affiliates {
  display: flex;
  flex-direction: column;
  padding: 92px 16px;
  font-family: "Oxygen", sans-serif;
  font-style: normal;
  color: #f0f0f0;

  @media (min-width: 768px) {
    padding: 72px 8px;
  }

  @media (min-width: 1024px) {
    padding: 110px 32px;
  }

  @media (min-width: 1440px) {
    padding: 120px 25px;
  }
}
</style>
