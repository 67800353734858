<template>
  <div id="app">
    <div class="wrapper">
      <router-view/>
    </div>
  </div>
</template>

<script>

export default {

}
</script>
<style lang="scss">
#app{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #010c28;
  * {
    box-sizing: border-box;
  }
}
</style>
