<template>
  <div class="HomePagePartnership">
    <h3 class="HomePagePartnership-Title" data-aos="fade-up" data-aos-duration="1500">
      Synergy and partnership
    </h3>
    <HomePagePartnershipCards />
  </div>
</template>

<script>
import HomePagePartnershipCards from "./HomePagePartnershipCards";

export default {
  name: "HomePagePartnership",
  components: { HomePagePartnershipCards },
};
</script>

<style lang="scss">
.HomePagePartnership {
  padding: 80px 25px 30px;
  font-family: "Oxygen", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #f0f0f0;

  @media (min-width: 768px) {
    padding: 130px 25px 30px;
  }

  &-Title {
    margin: 0 0 32px;
    font-size: 20px;
    line-height: 1.25;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;

    @media (min-width: 768px) {
      margin: 0 0 48px;
      font-size: 22px;
    }
    @media (min-width: 1024px) {
      margin: 0 0 50px;
      font-size: 28px;
    }
  }
}
</style>
