<template>
  <div class="HomePagePartnershipCards">
    <HomePagePartnershipCard
      v-for="({ title, text, countImg }, i) in cards"
      :key="i"
      :title="title"
      :text="text"
      :images="countImg"
      :count="i"
    />
  </div>
</template>

<script>
import HomePagePartnershipCard from "./HomePagePartnershipCard";
export default {
  name: "HomePagePartnershipCards",
  components: {
    HomePagePartnershipCard,
  },
  data: () => ({
    cards: [
      {
        countImg: 4,
        title: "OUR PEOPLE ARE OUR GREATEST ASSET",
        text:
          "We build our success on hiring industry experts who know exactly what our customers want. At DUXGroup, we don’t believe in micromanagement. Rather, we trust everyone to do their work to the highest standards, to support each other, and work with integrity. Our people are our greatest asset.",
      },
      {
        countImg: 2,
        title: "TRUST AND TRANSPARENCY",
        text:
          "We practice openness in our communications and company processes. By always being clear, honest and direct in our communications with our employees, and our dealings with our clients and partners, we have managed to foster and maintain healthy and strong relationships. We believe that honesty, transparency and integrity are also a two-way street and we look for these values in our employees and partners.",
      },
      {
        countImg: 4,
        title: "RESILIENCE THROUGH TEAMWORK",
        text:
          "Aiming high is something we’re proud of, however we’re also not too proud to admit that we sometimes need to readjust. Getting up after a fall and looking for different, innovative solutions is one of our strengths. In fact, we live by the 1+1=3 principle - through sharing knowledge and information with each other, we learn from our successes and mistakes and create something better.",
      },
      {
        countImg: 3,
        title: "ONE TEAM",
        text:
          "You won’t find outdated, hierarchical structures here at DUXGroup. We’re co-workers, sure, but we are above all, friends. We care about each other and act as one team in achieving our mission and in whatever we do. This is by far the most important value we hold. Our relationships with one another are crucial to our success.",
      },
    ],
  }),
};
</script>

<style lang="scss">
.HomePagePartnershipCards {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px 16px;
  }
  @media (min-width: 1024px) {
    gap: 32px 16px;
  }
  @media (min-width: 1024px) {
    gap: 30px;
  }
}
</style>
