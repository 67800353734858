<template>
  <div class="HomePagePartnershipCard">
    <img
      src="@/assets/images/partnership/partnership-bg.png"
      alt="border"
      class="HomePagePartnershipCard-Bg"
    />
    <img
      src="@/assets/images/partnership/card-hover-bg.png"
      alt="border"
      class="HomePagePartnershipCard-Hover"
    />
    <HomePagePartnershipAnimateImgs :images="images" :count="count" />
    <HomePagePartnershipContent :title="title" :text="text" />
  </div>
</template>

<script>
import HomePagePartnershipContent from "./HomePagePartnershipContent";
import HomePagePartnershipAnimateImgs from "./HomePagePartnershipAnimateImgs";

export default {
  name: "HomePagePartnershipCard",
  components: { HomePagePartnershipContent, HomePagePartnershipAnimateImgs },
  props: {
    count: {
      type: Number,
      require: true,
    },
    images: {
      type: Number,
      require: true,
    },
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.HomePagePartnershipCard {
  position: relative;
  padding: 30px 16px;

  @media (min-width: 1024px) {
    padding: 34px 24px 30px;
  }

  &:hover {
    .HomePagePartnershipCard-Hover {
      opacity: 1;
    }
    .HomePagePartnershipCard-Bg {
      opacity: 0;
    }
    .HomePagePartnershipAnimateImgs {
      width: 200px;
    }
  }

  &-Bg,
  &-Hover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.2s ease-in-out;
  }

  &-Hover {
    opacity: 0;
  }
}
</style>
