<template>
  <div
    class="HomePagePartnershipAnimateImgs"
    :class="`HomePagePartnershipAnimateImgs--animate${count + 1}`"
  >
    <img
      v-for="img in images"
      :key="img"
      :src="require(`@/assets/images/partnership/view${count + 1}/${img}.svg`)"
    />
  </div>
</template>

<script>
export default {
  name: "HomePagePartnershipAnimateImgs",
  props: {
    count: {
      type: Number,
      require: true,
    },
    images: {
      type: Number,
      require: true,
    },
  },
};
</script>

<style lang="scss">
.HomePagePartnershipAnimateImgs {
  position: relative;
  z-index: 1;
  width: 130px;
  height: 90px;
  transition: width 1s ease-in-out;

  > img {
    position: absolute;
  }

  &--animate1 {
    > img {
      position: absolute;

      &:first-child {
        width: 22px;
        height: 22px;
        bottom: 10%;
        right: 20%;
      }
      &:nth-child(2) {
        width: 29px;
        height: 29px;
        bottom: 20%;
        left: 0;
      }
      &:nth-child(3) {
        width: 50px;
        height: 50px;
        top: 0;
        right: 0;
      }
      &:nth-child(4) {
        width: 62px;
        height: 62px;
        bottom: 15%;
        left: 30%;
      }
    }
  }

  &--animate2 {
    width: 155px;
    > img {
      width: auto;
      position: absolute;

      &:first-child {
        left: 0;
        bottom: 0;
      }
      &:nth-child(2) {
        right: 0;
        top: 0;
      }
    }
  }

  &--animate3 {
    > img {
      position: absolute;
      width: auto;

      &:first-child {
        left: 0;
        bottom: 0;
      }
      &:nth-child(2) {
        left: 10%;
        bottom: 0;
      }
      &:nth-child(3) {
        left: 27%;
        bottom: 0;
      }
      &:nth-child(4) {
        left: 45%;
        bottom: 0;
      }
    }
  }

  &--animate4 {
    > img {
      position: absolute;
      width: auto;

      &:first-child {
        top: 0;
        left: 0;
      }
      &:nth-child(2) {
        bottom: -3px;
        left: 30%;
        transition: 1s;
      }
      &:nth-child(3) {
        bottom: -3px;
        left: 58%;
      }
    }
  }
}
</style>
